/*	public String key;
	public String name;
	public String type;
	public Long lastUpdated;
	public Long created;
	public Boolean archived;
	public String rule; // ruleName
	public String testLimitKey; // ruleId
	public Boolean runOLTSTest = false;
	public Boolean runORLTest = false;
	public String orlTestLimitKey;
	public String orlRule;
	public Ends ends;
	public PairMode pairMode;
	public Directionality direction;
	public TestMethod testMethod;
	public FiberMode fiberMode;
	public FiberType fiberType;
 * */
angular.module('aerosApp')
	.constant("singleOltsTestDisplay", [
	        {id: "name", name: "Test Name", type: "string", value: "n/a"},
	        {id: "description", name: "Description", type: "string", value: ""},
			{id: "type", name: "Test", type: "string", value: "n/a"},
			{id: "fiberMode", name: "Mode", type: "string", value: "n/a"},
			{id: "fiberType", name: "Fiber Type", type: "string", value: "n/a"},
			{id: "runOLTSTest", name: "Run OLTS", type: "boolean", value: "false"},
			{id: "rule", name: "OLTS Test Limit", type: "string", value: "None"},
			{id: "runORLTest", name: "Run ORL", type: "string", value: "false"},
			{id: "orlRule", name: "ORL Test Limit", type: "string", value: "None"},
			{id: "direction", name: "Directionality", type: "string", value: "n/a"},
            {id: "unidirectionalDirection", name: "Direction", type: "string", value: "n/a"},
			{id: "testMethod", name: "Reference Method", type: "string", value: "n/a"},
			{id: "created", name: "Created", type: "date", value: "n/a"},
			{id: "lastUpdated", name: "Updated", type: "date", value: "n/a"}
		])
		.constant("multiOltsTestDisplay", [
	        {id: "name", name: "Test Name", type: "string", value: "n/a"},
            {id: "description", name: "Description", type: "string", value: ""},
            {id: "type", name: "Test", type: "string", value: "n/a"},
			{id: "fiberMode", name: "Mode", type: "string", value: "n/a"},
			{id: "fiberType", name: "Fiber Type", type: "string", value: "n/a"},
			{id: "rule", name: "OLTS Test Limit", type: "string", value: "None"},
			{id: "direction", name: "Directionality", type: "string", value: "n/a"},
            {id: "unidirectionalDirection", name: "Direction", type: "string", value: "n/a"},
			{id: "testMethod", name: "Reference Method", type: "string", value: "n/a"},
			{id: "created", name: "Created", type: "date", value: "n/a"},
			{id: "lastUpdated", name: "Updated", type: "date", value: "n/a"}
		])
		.constant("inspectionTestDisplay", [
	        {id: "name", name: "Test Name", type: "string", value: "n/a"},
			{id: "type", name: "Test", type: "string", value: "n/a"},
			{id: "fiberMode", name: "Mode", type: "string", value:"n/a"},
			{id: "ends", name: "Ends", type: "string", value: "n/a"},
			{id: "rule", name: "P/F Standard", type: "string", value: "None"},
			{id: "created", name: "Created", type: "date", value: "n/a"},
			{id: "lastUpdated", name: "Updated", type: "date", value: "n/a"}
		]);
